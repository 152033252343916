import { gql } from '@apollo/client';

export const getCohortDashboardForLearner_Name = 'GetCohortDashboardForLearner';
export const getCohortDashboardForLearner_Gql = gql`
  query GetCohortDashboardForLearner($cohortId: String!) {
    getCohortDashboardForLearner(cohortId: $cohortId) {
      allSessions {
        id
        title
        number
        startDate
        endDate
        HubMemberCohortSession {
          id
          hubRoleId
          hubMemberCohort {
            id
            facilitateAll
            presentAll
            panelistAll
            credentials
            hide
            user {
              id
              firstName
              lastName
            }
          }
          hubRole {
            id
            name
          }
        }
      }
      allowPreregistration
      internalTitle
      externalTitle
      evaluationGoal
      learningObjectives
      audience
      agreement
      partnerName
      registrationOpenDate
      registrationCloseDate
      startDate
      endDate
      isOngoing
      meetingLink
      caseFormLink
      surveyLink
      scheduleDescription
      registrationQuestions {
        id
        title
        type
        name
        required
        isMultiSelect
        yesNo {
          noText
          yesText
        }
        options {
          label
          value
        }
        hasOtherOption
        scale {
          label
        }
        statements {
          name
          statement
        }
      }
      sessions {
        id
        title
        number
        startDate
        endDate
        sessionResources {
          id
          resource {
            name
            displayName
            url
            archived
            deleted
          }
        }
      }
    }
  }
`;
