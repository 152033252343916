import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import Label from './Label';
import Attendance from '../../Session/Attendance';

import { UpcomingSessionListPropsForStaff } from '../../cohort/Cohort.interfaces';
import { convertDateToTimeZoneFromUtc } from 'helpers/dateHelpers';

const UpcomingSessionListForStaff = ({ cohortId, cohortDashboard, refetchDashboard }: UpcomingSessionListPropsForStaff) => {
  return (
    <>
      <Label text="Upcoming Sessions" />
      <Table size="small">
        <TableBody>
          {cohortDashboard.sessions &&
            cohortDashboard.sessions.map((session) => (
              <TableRow key={session.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>{convertDateToTimeZoneFromUtc(session.startDate, true, false, false)}</TableCell>
                <TableCell>{convertDateToTimeZoneFromUtc(session.endDate, true, false, true)}</TableCell>
                <TableCell>
                  {session.number} - {session.title}
                </TableCell>
                <TableCell>
                  <Attendance 
                    cohortId={cohortId} 
                    sessionId={session.id} 
                    sessionName={session.title ?? ''} 
                    sessionStartDate={session.startDate ?? ''} 
                    sessionEndDate={session.endDate ?? ''} 
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </>
  );
};

export default UpcomingSessionListForStaff;
