import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useParams, useHistory } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import Breadcrumbs, { BreadcrumbItem } from '../components/global/Breadcrumbs';
import PageHeader from '../components/global/PageHeader';
import SortableTableColumn, { SortProperty, sortBy } from '../components/global/SortableTableColumn';
import ProgressIndicator from '../components/global/ProgressIndicator';

import PreRegistration from '../components/learner/PreRegistration/PreRegistration';
import SectionHeader from 'components/learner/SectionHeader/SectionHeader';
import TextSection from 'components/learner/TextSection/TextSection';
import LabelAndValue from '../components/admin/CohortDetails/components/LabelAndValue';

import { convertDateToTimeZoneFromUtc, convertDateToTimeFromUtc } from 'helpers/dateHelpers';
import { useAuth } from '../hooks/useAuth';
import CohortParams from '../models/params/CohortParams';
import { getCohortDashboardForLearner_Gql } from '../gql/cohort/getCohortDashboardForLearner';
import { getRegistrationsForUser_Gql } from '../gql/cohortRegistration/getRegistrationsForUser';
import {
  GetCohortDashboardForLearner,
  GetCohortByIdVariables,
  GetCohortDashboardForLearner_getCohortDashboardForLearner,
  GetRegistrationsForUser,
  GetRegistrationsForUserVariables,
  GetRegistrationsForUser_getRegistrationsForUser,
} from '../models/GeneratedModels';
import { Container } from '@mui/material';

const CohortDetails = () => {
  const auth = useAuth();
  const history = useHistory();
  const { cohortId } = useParams<CohortParams>();

  const { data, loading } = useQuery<
    GetCohortDashboardForLearner,
    GetCohortByIdVariables
  >(getCohortDashboardForLearner_Gql, {
    variables: {
      cohortId,
    },
  });

  //TODO: Combine this with preReg queries
  const { data: regData, loading: regLoading } = useQuery<
    GetRegistrationsForUser,
    GetRegistrationsForUserVariables
  >(getRegistrationsForUser_Gql, {
    variables: {
      userId: auth.user?.id ?? '',
    },
    skip: !auth.user?.id, // Skip the query if no userId
    onError: (error) => {
      // Optionally log the error but don't show it to the user
      console.debug('Registration query skipped or failed:', error);
    }
  });

  const cohort: GetCohortDashboardForLearner_getCohortDashboardForLearner | undefined =
    data?.getCohortDashboardForLearner;
  const registrations: GetRegistrationsForUser_getRegistrationsForUser[] =
    regData?.getRegistrationsForUser ?? [];
  // Assume not registered if no userId or no registrations
  const userAlreadyRegistered = auth.user?.id ? registrations.some(
    (r) => r.cohortId === cohortId
  ) : false;

  const breadcrumbs: BreadcrumbItem[] = [
    {
      to: '/programs',
      text: 'Browse Programs',
      isActive: true,
    },
    {
      text: cohort?.externalTitle ?? '',
      isActive: false,
    },
  ];

  const signupClick = async () => {
    history.push(`/registration/${cohortId}`);
  };

  const formatDate = (
    date: Date | null,
    noDate: boolean,
    includeTime: boolean,
    isOngoing?: boolean | null,
  ): string => {
    if (isOngoing) return 'Cohort is Ongoing';

    if (!date) return '';

    if (noDate) {
      return convertDateToTimeFromUtc(date, !noDate, false);
    } else {
      return convertDateToTimeZoneFromUtc(date, includeTime, false);
    }
  };

  const [sortProp, setSortProp] = useState<SortProperty>();
  const today = new Date();

  return (
    <>
      <ProgressIndicator isOpen={loading || regLoading} title="Loading" />
      <Container
        maxWidth="xl"
        sx={{
          p: 10,
          mb: 12,
          '& ul, & ol': {
            marginLeft: '17px'
          }
        }}
      >
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        {cohort && (
          <>
            <PageHeader title={cohort.externalTitle ?? ''} />
            <br /><br />
            <Box sx={{ mb: 2 }}>
              <Grid container spacing={9} sx={{ pb: 2 }}>
                <Grid item xs={6} md={8}>
                  <LabelAndValue label="When This ECHO Meets" value={cohort.scheduleDescription} />
                  <LabelAndValue label="What This ECHO Will Focus On" value={cohort.evaluationGoal} />
                  <LabelAndValue label="Learning Objectives/Outcomes" value={cohort.learningObjectives} />
                </Grid>
                <Grid item xs={6} md={4} sx={{ pl: 10 }}>
                  <Typography variant="body1">
                    <LabelAndValue label="Start Date" value={formatDate(cohort.startDate, false, false)} />  
                  </Typography>
                  <br />
                  <Typography variant="body1">
                    <LabelAndValue label="End Date" value={formatDate(cohort.endDate, false, false, cohort.isOngoing)} />
                  </Typography>
                </Grid>
              </Grid>

              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <SortableTableColumn
                        currentSort={sortProp?.prop}
                        onSort={setSortProp}
                        sortProperty="number"
                        headerText="Session/Title"
                        align="left"
                      />

                      <SortableTableColumn
                        currentSort={sortProp?.prop}
                        onSort={setSortProp}
                        sortProperty="startDate"
                        headerText="Date"
                        align="left"
                      />

                      <SortableTableColumn
                        currentSort={sortProp?.prop}
                        onSort={setSortProp}
                        sortProperty="startDate"
                        headerText="Time"
                        align="left"
                      />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {cohort.allSessions &&
                      sortBy(cohort.allSessions, sortProp).map((session) => (
                        <TableRow
                          key={session.id}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <TableCell align="left">{`${session.number} - ${session.title}`}</TableCell>

                          <TableCell align="left">
                            {formatDate(session.startDate, false, false)}
                          </TableCell>

                          <TableCell align="left">
                            {formatDate(session.startDate, true, true)}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid container spacing={2} sx={{ pb: 2, mt: 1, textAlign: 'center' }}>
                <Grid item xs={12} md={12}>
                  {userAlreadyRegistered && (
                    <Alert 
                    severity="info"
                    sx={{ 
                      mt: 4,
                      display: 'flex',
                      justifyContent: 'center',
                      '& .MuiAlert-icon': {
                        marginRight: 1,
                        padding: 0,
                        alignSelf: 'center'
                      },
                      '& .MuiAlert-message': {
                        textAlign: 'center',
                        padding: 0,
                        display: 'inline-flex',
                        alignItems: 'center'
                      }
                    }}
                  >
                      You have already requested registration for this cohort
                    </Alert>                  
                  )}

                  {!userAlreadyRegistered && cohort.allowPreregistration && (!cohort.registrationOpenDate || new Date(cohort.registrationOpenDate) > today) && (
                    <PreRegistration
                      cohortTitle={cohort.externalTitle ?? ''}
                      cohortId={cohortId}
                    />
                  )}

                  {!userAlreadyRegistered && (cohort.registrationOpenDate && new Date(cohort.registrationOpenDate) <= today) && (
                    <Button 
                      sx={{ mb: 3 }}
                      onClick={signupClick}
                      variant="contained"
                      color="primary"
                    >
                      Sign Up For This ECHO
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </Container>
    </>
  );
};

export default CohortDetails;
