import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import {
  Alert,
  Button,
  Grid,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Typography,
} from '@mui/material';

import { useAuth } from '../../../hooks/useAuth';
import PageHeader from '../../../components/global/PageHeader';

import { convertDateToTimeZoneFromUtc, convertDateToTimeFromUtc } from 'helpers/dateHelpers';
import SortableTableColumn, { SortProperty, sortBy } from 'components/global/SortableTableColumn';

import ProgressIndicator from 'components/global/ProgressIndicator';
import LabelAndValue from '../CohortDetails/components/LabelAndValue';
import PreRegistration from 'components/learner/PreRegistration/PreRegistration';


import { getCohortDashboardForStaff_Gql } from '../../../gql/cohort/getCohortDashboardForStaff';
import { getRegistrationsForUser_Gql } from '../../../gql/cohortRegistration/getRegistrationsForUser';
import {
  GetCohortDashboardForStaff,
  GetCohortByIdVariables,
  GetCohortDashboardForStaff_getCohortDashboardForStaff,
  GetRegistrationsForUser,
  GetRegistrationsForUserVariables,
  GetRegistrationsForUser_getRegistrationsForUser,
} from '../../../models/GeneratedModels';

import { Theme, createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    editor: {
      height: '300px',
    },
    accountSelect: {
      width: '100%',
      marginBottom: 2,
      textAlign: 'left',
    },
  })
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface ViewAsLearnerUnregisteredProps {
    cohortId: string;
    isOpen: boolean;
    onClose: () => void;
}

const ViewAsLearnerUnregistered = (props: ViewAsLearnerUnregisteredProps) => {
  const {
    isOpen,
    onClose,
    cohortId,
  } = props;
  
  const getDialogTitle = () => {
     return 'View as UnRegistered Learner';
  };
  const auth = useAuth();
  const history = useHistory();

  const { data, loading } = useQuery<
    GetCohortDashboardForStaff,
    GetCohortByIdVariables
  >(getCohortDashboardForStaff_Gql, {
    variables: {
      cohortId,
    },
  });

  //TODO: Combine this with preReg queries
  const { data: regData, loading: regLoading } = useQuery<
    GetRegistrationsForUser,
    GetRegistrationsForUserVariables
  >(getRegistrationsForUser_Gql, {
    variables: {
      userId: auth.user?.id ?? '',
    },
  });

  const cohort: GetCohortDashboardForStaff_getCohortDashboardForStaff | undefined =
    data?.getCohortDashboardForStaff;
  const registrations: GetRegistrationsForUser_getRegistrationsForUser[] =
    regData?.getRegistrationsForUser ?? [];
  const userAlreadyRegistered = registrations.some(
    (r) => r.cohortId === cohortId
  );

  const signupClick = async () => {
    const url = `/registration/${cohortId}`;
    window.open(url, '_blank');
  };

  const formatDate = (
    date: Date | null,
    noDate: boolean,
    includeTime: boolean,
    isOngoing?: boolean | null,
  ): string => {
    if (isOngoing) return 'Cohort is Ongoing';

    if (!date) return '';

    if (noDate) {
      return convertDateToTimeFromUtc(date, !noDate, false);
    } else {
      return convertDateToTimeZoneFromUtc(date, includeTime, false);
    }
  };

  const [sortProp, setSortProp] = useState<SortProperty>();
  const today = new Date();

  const handleClose = () => {
    onClose();
  };

  const closeDialog = () => {
    handleClose();
  };

  return (
    <>
      <Dialog
        open={isOpen}
        PaperProps={{ 
          sx: {
            width: '80vw', // 80% of the viewport width
            height: '80vh', // 60% of the viewport height
            maxWidth: 'none', // To ensure dialog width is always responsive
            maxHeight: 'none', // To ensure dialog height is always responsive
            '& ul, & ol': {
              marginLeft: '17px'
            }
          },
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">{getDialogTitle()}</DialogTitle>
        <DialogContent>
          <>
            <ProgressIndicator isOpen={loading || regLoading} title="Loading" />

            {cohort && (
              <>
                <PageHeader title={cohort.externalTitle ?? ''} />
                <br /><br />
                <Box sx={{ mt: 2, mb: 2 }}>
                  <Grid container spacing={9} sx={{ pb: 2 }}>
                    <Grid item xs={6} md={8}>
                      <LabelAndValue label="When This ECHO Meets" value={cohort.scheduleDescription} />
                      <LabelAndValue label="What This ECHO Will Focus On" value={cohort.evaluationGoal} />
                      <LabelAndValue label="Learning Objectives/Outcomes" value={cohort.learningObjectives} />
                    </Grid>
                    <Grid item xs={6} md={4} sx={{ pl: 10 }}>
                      <Typography variant="body1">
                        <LabelAndValue label="Start Date" value={formatDate(cohort.startDate, false, false)} />  
                      </Typography>
                      <br />
                      <Typography variant="body1">
                        <LabelAndValue label="End Date" value={formatDate(cohort.endDate, false, false, cohort.isOngoing)} />
                      </Typography>
                    </Grid>
                  </Grid>

                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <SortableTableColumn
                            currentSort={sortProp?.prop}
                            onSort={setSortProp}
                            sortProperty="number"
                            headerText="Session/Title"
                            align="left"
                          />

                          <SortableTableColumn
                            currentSort={sortProp?.prop}
                            onSort={setSortProp}
                            sortProperty="startDate"
                            headerText="Date"
                            align="left"
                          />

                          <SortableTableColumn
                            currentSort={sortProp?.prop}
                            onSort={setSortProp}
                            sortProperty="startDate"
                            headerText="Time"
                            align="left"
                          />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {cohort.allSessions &&
                          sortBy(cohort.allSessions, sortProp).map((session) => (
                            <TableRow
                              key={session.id}
                              sx={{
                                '&:last-child td, &:last-child th': { border: 0 },
                              }}
                            >
                              <TableCell align="left">{`${session.number} - ${session.title}`}</TableCell>

                              <TableCell align="left">
                                {formatDate(session.startDate, false, false)}
                              </TableCell>

                              <TableCell align="left">
                                {formatDate(session.startDate, true, true)}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>

                {userAlreadyRegistered && (
                  <Alert severity="info">
                    You have already requested registration for this cohort
                  </Alert>
                )}

                {!userAlreadyRegistered && cohort.allowPreregistration && (!cohort.registrationOpenDate || new Date(cohort.registrationOpenDate) > today) && (
                  <PreRegistration
                    cohortTitle={cohort.externalTitle ?? ''}
                    cohortId={cohortId}
                  />
                )}

                {!userAlreadyRegistered && (cohort.registrationOpenDate && new Date(cohort.registrationOpenDate) <= today) && (
                  <Button
                    sx={{ mb: 3 }}
                    onClick={signupClick}
                    variant="contained"
                    color="primary"
                  >
                    Sign Up For This ECHO
                  </Button>
                )}
              </>
            )}
          </>
        </DialogContent>
        <DialogActions>
            <>
              <Button
                sx={{ width: '200px' }}
                onClick={closeDialog}
                variant="contained"
                color="primary"
              >
                Close
              </Button>
            </>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ViewAsLearnerUnregistered;
