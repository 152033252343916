import { getDateStamp } from 'helpers/dateHelpers';
import { QuickLinksPropsForStaff } from '../../cohort/Cohort.interfaces';
import Label from './Label';

import LabelAndValue from './LabelAndValue';
import LabelAndValueLink from './LabelAndValueLink';
import moment from 'moment';

const QuickLinksForStaff = ({ cohortId, cohortDashboard }: QuickLinksPropsForStaff) => {
  const cohortRegistrationOpenDate = cohortDashboard?.registrationOpenDate;
  const cohortRegistrationCloseDate = cohortDashboard?.registrationCloseDate;
  const now = moment();

  return (
    <>
      <Label text="Quick Links" />

      {(cohortRegistrationOpenDate && cohortRegistrationOpenDate.length 
          && moment(cohortRegistrationOpenDate).subtract(1, 'day').isBefore(getDateStamp())) && 
          <LabelAndValueLink label="Learner Cohort Details" link={`/cohortDetails/${cohortId}`} />
      }
      <LabelAndValueLink label="Video Conference" link={cohortDashboard.meetingLink} />
      {((cohortRegistrationCloseDate && cohortRegistrationCloseDate.length 
          && moment(cohortRegistrationCloseDate).add(1, 'day').isBefore(now)) ||
        (cohortRegistrationOpenDate && cohortRegistrationOpenDate.length 
          && moment(cohortRegistrationOpenDate).isAfter(now))) ? 
          <LabelAndValue label="" value="Registration is currently closed" /> :
        <LabelAndValueLink label="Registration" link={`/registration/${cohortId}`} />
      }
      <LabelAndValueLink label="Case Form" link={cohortDashboard.caseFormLink} />
      <LabelAndValueLink label="Survey" link={cohortDashboard.surveyLink} />
    </>
  );
};

export default QuickLinksForStaff;
