import { RegistrantSummaryPropsForStaff } from '../../cohort/Cohort.interfaces';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import Label from './Label';

const RegistrantSummaryForStaff = ({ cohortDashboard }: RegistrantSummaryPropsForStaff) => {
  return (
    <>
      <Label text="Registrant Summary" />

      <Table size="small">
        <TableBody>
          <TableRow>
            <TableCell>Pre-Registrants</TableCell>
            <TableCell align="right">{cohortDashboard.preRegistrantCount}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>New Registrants</TableCell>
            <TableCell align="right">{cohortDashboard.newRegistrantCount}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Registered</TableCell>
            <TableCell align="right">{cohortDashboard.registeredCount}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Waitlisted</TableCell>
            <TableCell align="right">{cohortDashboard.waitlistedCount}</TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell>Denied</TableCell>
            <TableCell align="right">{cohortDashboard.deniedCount}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

export default RegistrantSummaryForStaff;
