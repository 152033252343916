import { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useParams, useHistory } from 'react-router-dom';
import { Grid, Typography, Box, Alert, Button, Container } from '@mui/material';

import LearnerRegistrationQuestions from '../components/learner/Registration/LearnerRegistrationQuestions';
import PageHeader from '../components/global/PageHeader';
import ProgressIndicator from '../components/global/ProgressIndicator';
import SectionHeader from 'components/learner/SectionHeader/SectionHeader';
import TextSection from 'components/learner/TextSection/TextSection';
import LabelAndValue from '../components/admin/CohortDetails/components/LabelAndValue';

import moment from 'moment';
import { convertDateToTimeZoneFromUtc } from 'helpers/dateHelpers';
import { useAuth } from '../hooks/useAuth';
import CohortParams from '../models/params/CohortParams';
import { getCohortDashboardForLearner_Gql } from '../gql/cohort/getCohortDashboardForLearner';
import { addCohortRegistrationMutation_Gql } from '../gql/cohortRegistration/addCohortRegistrationMutation';
import { getRegistrationsForUser_Gql, getRegistrationsForUser_Name } from '../gql/cohortRegistration/getRegistrationsForUser';
import {
  GetCohortDashboardForLearner,
  GetCohortByIdVariables,
  GetCohortDashboardForLearner_getCohortDashboardForLearner,
  AddCohortRegistrationVariables,
  GetRegistrationsForUser,
  GetRegistrationsForUserVariables,
  GetRegistrationsForUser_getRegistrationsForUser,
} from '../models/GeneratedModels';
import RegistrationUnavailableSplash from './RegistrationUnavailableSplash';

interface RegistrationConfirmationProps {
  title: string;
  firstName: string;
}

const RegistrationConfirmation = ({
  title,
  firstName,
}: RegistrationConfirmationProps) => {
  const history = useHistory();

  return (
    <>
      <Container maxWidth="xl" sx={{ p: 14, mb: 12, textAlign: 'center' }}>
        <Alert sx={{ mb: 4 }} severity="success">
          <Typography variant="body1">
            {firstName}, your registration request has been received for {title}.
            Please check your email for next steps.
          </Typography>
        </Alert>
        <Button
          variant="contained"
          color="primary"
          onClick={() => history.push('/programs')}
        >
          Browse ECHO Programs
        </Button>
      </Container>
    </>
  );
};

const CohortRegistration = () => {
  const auth = useAuth();
  const { cohortId } = useParams<CohortParams>();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [registrationClosed, setRegistrationClosed] = useState(false);
  const [registrationOpen, setRegistrationOpen] = useState(true);

  const { data: regData, loading: regLoading } = useQuery<
    GetRegistrationsForUser,
    GetRegistrationsForUserVariables
  >(getRegistrationsForUser_Gql, {
    variables: {
      userId: auth.user?.id ?? '',
    },
    skip: !auth.user?.id,
    onError: (error) => {
      console.debug('Registration query skipped or failed:', error);
    }
  });

  const { data, loading } = useQuery<
    GetCohortDashboardForLearner,
    GetCohortByIdVariables
  >(getCohortDashboardForLearner_Gql, {
    variables: {
      cohortId,
    },
  });

  const [addCohortRegistration, { loading: isSaving, error }] = useMutation<
    void,
    AddCohortRegistrationVariables
  >(addCohortRegistrationMutation_Gql, {
    refetchQueries: [getRegistrationsForUser_Name],
  });

  const cohort: GetCohortDashboardForLearner_getCohortDashboardForLearner | undefined =
    data?.getCohortDashboardForLearner;
  const registrations: GetRegistrationsForUser_getRegistrationsForUser[] =
    regData?.getRegistrationsForUser ?? [];
  const userAlreadyRegistered = auth.user?.id ? registrations.some(
    (r) => r.cohortId === cohortId
  ) : false;
  const cohortRegistrationOpenDate = cohort?.registrationOpenDate;
  const cohortRegistrationCloseDate = cohort?.registrationCloseDate;
    
  useEffect(() => {
    if (loading || isSaving || !data) return;

    const now = moment(); // Get current date/time
    if (cohortRegistrationCloseDate && cohortRegistrationCloseDate.length && moment(cohortRegistrationCloseDate).add(1, 'day').isBefore(now)) {
      setRegistrationClosed(true);
    }
    
    if (cohortRegistrationOpenDate && cohortRegistrationOpenDate.length && moment(cohortRegistrationOpenDate).isAfter(now)) {
      setRegistrationOpen(false);
    }
}, [loading, cohortRegistrationOpenDate, cohortRegistrationCloseDate, isSaving, data]);

  if (!registrationOpen || registrationClosed) {
    return <RegistrationUnavailableSplash />;
  }

  const onSubmit = async ({ isAgreed, ...values }: any) => {
    let updatedValues = { ...values };

    if (cohort?.registrationQuestions) {
      cohort?.registrationQuestions.forEach((question) => {
        const answer = updatedValues[question.name];
        
        if (question.hasOtherOption) {
          if (Array.isArray(answer) && answer.includes("Other")) {
            const otherKey = `${question.name}Other`;
            if (otherKey in updatedValues) {
              const otherValueIndex = answer.indexOf("Other");
              if (otherValueIndex !== -1) {
                answer[otherValueIndex] = `Other Answer: ${updatedValues[otherKey]}`;
              }
              updatedValues[question.name] = answer;
            }
          } else if (answer === "Other") {
            const otherKey = `${question.name}Other`;
            if (otherKey in updatedValues) {
              updatedValues[question.name] = `Other Answer: ${updatedValues[otherKey]}`;
            }
          }
        }

        let currentAnswer = updatedValues[question.name];
        if (Array.isArray(currentAnswer) && currentAnswer.length > 1) {
          updatedValues[question.name] = currentAnswer.join('; ');
        }
      });
    }

    try {
      await addCohortRegistration({
        variables: {
          cohortRegistration: {
            cohortId,
            userId: auth.user?.id ?? '',
            isActive: true,
            isAgreed,
            copiedFromWaitlist: false,
            registrationAnswers: updatedValues ? JSON.stringify(updatedValues) : undefined,
          },
        },
    });
      setShowConfirmation(true);
    } catch {
      console.log('error', error);
    }
  };

  const formatDate = (
    date?: Date | null,
    includeTime?: boolean,
    isOngoing?: boolean | null
  ): string => {
    if (isOngoing) return 'Cohort is Ongoing';

    if (!date) return '';

    return convertDateToTimeZoneFromUtc(date, includeTime, false);
  };

  if (showConfirmation) {
    return (
      <RegistrationConfirmation
        title={cohort?.externalTitle ?? ''}
        firstName={auth.user?.fullName ?? ''}
      />
    );
  }

  return (
    <>
      <ProgressIndicator isOpen={loading || regLoading || isSaving} title="Loading..." />
      <Container
        maxWidth="xl"
        sx={{
          p: 14,
          mb: 12,
          '& ul, & ol': {
            marginLeft: '17px'
          }
        }}>
        {error && (
          <Alert severity="error">{error.graphQLErrors[0].message}</Alert>
        )}

        {cohort && (
          <>
            <PageHeader title={cohort.externalTitle ?? ''} />

            <Box sx={{ mt: 2, mb: 2 }}>
              <Grid container>
                <Grid item xs={6} md={8}>
                  <LabelAndValue label="When This ECHO Meets" value={cohort.scheduleDescription} />
                  <LabelAndValue label="What This ECHO Will Focus On" value={cohort.evaluationGoal} />
                  <LabelAndValue label="Learning Objectives/Outcomes" value={cohort.learningObjectives} />
                  <LabelAndValue label="Audience" value={cohort.audience} />
                </Grid>
                <Grid item xs={6} md={4} sx={{ pl: 2 }}>
                  <LabelAndValue
                    label="Start Date"
                    value={formatDate(cohort.startDate, false)} />

                  <LabelAndValue
                    label="End Date"
                    value={formatDate(cohort.endDate, false, cohort.isOngoing)} />
                </Grid>
              </Grid>

              {userAlreadyRegistered ? (
                <Box display="flex" justifyContent="center" mt={4}>
                  <Alert severity="info">
                    You have already requested registration for this cohort
                  </Alert>
                </Box>
              ) : (
                <LearnerRegistrationQuestions
                  onSubmit={onSubmit}
                  cohort={cohort}
                  saveButtonText="Register for Cohort"
                />
              )}
            </Box>
          </>
        )}
      </Container>
    </>
  );
};

export default CohortRegistration;
