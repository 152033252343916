import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Divider, Grid, Box } from '@mui/material';

import ProgressIndicator from 'components/global/ProgressIndicator';
import HubMemberSessions from 'components/admin/CohortDetails/components/HubMemberSessions';
import { convertDateToTimeZoneFromUtc } from 'helpers/dateHelpers';

import PageHeader from '../../global/PageHeader';

import LabelAndValue from '../CohortDetails/components/LabelAndValue';
import UpcomingSessionListForStaff from '../CohortDetails/components/UpcomingSessionListForStaff';
import QuickLinksForStaff from '../CohortDetails/components/QuickLinksForStaff';
import RegistrantSummaryForStaff from '../CohortDetails/components/RegistrantSummaryForStaff';
import HubTeamList from '../CohortDetails/components/HubTeamList';
import FileExports from '../CohortDetails/components/FileExports';

import {
  GetCohortDashboardForStaff_getCohortDashboardForStaff,
  GetMembersByCohort,
  GetMembersByCohortVariables,
  GetMembersByCohort_getMembersByCohort
} from '../../../models/GeneratedModels';
import { getCohortDashboardForStaff_Gql } from '../../../gql/cohort/getCohortDashboardForStaff';
import { getMembersByCohort_Gql } from '../../../gql/hubTeam/getMembersByCohort';
import { CohortDetailsProps } from './Cohort.interfaces';

const CohortDetails = ({ cohortId }: CohortDetailsProps) => {
  const [cohortDashboard, setCohortDashboard] =
    useState<GetCohortDashboardForStaff_getCohortDashboardForStaff>();
  const [excelExporting, setExcelExporting] = useState(false);

  const { loading, data, refetch } = useQuery(getCohortDashboardForStaff_Gql, {
    variables: {
      cohortId,
    },
  });

  const { loading: teamLoading, data: teamData } = useQuery<GetMembersByCohort, GetMembersByCohortVariables>(
    getMembersByCohort_Gql,
    {
      variables: {
        cohortId,
      },
    }
  );

  const hubTeamMembers: GetMembersByCohort_getMembersByCohort[] = teamData?.getMembersByCohort ?? [];

  useEffect(() => {
    if (loading || !data) return;

    setCohortDashboard(data.getCohortDashboardForStaff);
  }, [data, loading]);

  return (
    <>
      <ProgressIndicator isOpen={loading} title="Loading..." />
      <ProgressIndicator isOpen={excelExporting} title="Processing Excel export..." />
      
      {cohortDashboard && (
        <>
          <PageHeader title={cohortDashboard.internalTitle ?? ''} />

          <Box>
            <Grid container spacing={3}>
              <Grid item xs={7}>
                <LabelAndValue
                  label="External Title"
                  value={cohortDashboard.externalTitle}
                />
                <LabelAndValue
                  label="Project Partner(s)"
                  value={cohortDashboard.partnerName}
                />
                <LabelAndValue
                  label="Goal"
                  value={cohortDashboard.evaluationGoal}
                />
                <LabelAndValue
                  label="Learning Objectives/Outcomes"
                  value={cohortDashboard.learningObjectives}
                />
                <LabelAndValue
                  label="Audience"
                  value={cohortDashboard.audience}
                />

                <Divider sx={{ mb: 2 }} />

                <Grid container>
                  <Grid item xs={6}>
                    <LabelAndValue
                      label="Registration Open Date"
                      value={convertDateToTimeZoneFromUtc(
                        cohortDashboard.registrationOpenDate
                      )}
                    />

                    <LabelAndValue
                      label="Registration Close Date"
                      value={
                        cohortDashboard.isOngoing
                          ? 'Ongoing'
                          : convertDateToTimeZoneFromUtc(
                              cohortDashboard.registrationCloseDate
                            )
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <LabelAndValue
                      label="Start Date"
                      value={convertDateToTimeZoneFromUtc(
                        cohortDashboard.startDate,
                        false
                      )}
                    />

                    <LabelAndValue
                      label="End Date"
                      value={
                        cohortDashboard.isOngoing
                          ? 'Ongoing'
                          : convertDateToTimeZoneFromUtc(
                              cohortDashboard.endDate,
                              false
                            )
                      }
                    />
                  </Grid>
                </Grid>

                <Divider sx={{ mb: 2 }} />

                <UpcomingSessionListForStaff
                  cohortId={cohortId}
                  cohortDashboard={cohortDashboard}
                  refetchDashboard={refetch}
                />
              </Grid>
              <Grid item xs={5}>
                <QuickLinksForStaff
                  cohortId={cohortId}
                  cohortDashboard={cohortDashboard}
                />

                <Divider sx={{ mb: 2 }} />

                <RegistrantSummaryForStaff cohortDashboard={cohortDashboard} />

                <Divider sx={{ mb: 2 }} />

                <HubTeamList
                  development={cohortDashboard.developmentUser}
                  implementation={cohortDashboard.implementationUser}
                  evaluation={cohortDashboard.evaluationUser}
                  developmentSupportUser={cohortDashboard.developmentSupportUser}
                  implementationSupportUser={cohortDashboard.implementationSupportUser}
                  evaluationSupportUser={cohortDashboard.evaluationSupportUser}
                />

                <HubMemberSessions
                  hubTeamMembers={hubTeamMembers}
                />

                <FileExports
                  cohortId={cohortId}
                  internalLabel={cohortDashboard.internalTitle ?? ''}
                  onExportStart={setExcelExporting}
                />
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};

export default CohortDetails;
