import { gql } from '@apollo/client';

export const getCohortDashboardForStaff_Name = 'GetCohortDashboardForStaff';
export const getCohortDashboardForStaff_Gql = gql`
  query GetCohortDashboardForStaff($cohortId: String!) {
    getCohortDashboardForStaff(cohortId: $cohortId) {
      allSessions {
        id
        title
        number
        startDate
        endDate
        HubMemberCohortSession {
          id
          hubRoleId
          hubMemberCohort {
            id
            facilitateAll
            presentAll
            panelistAll
            credentials
            hide
            user {
              id
              firstName
              lastName
            }
          }
          hubRole {
            id
            name
          }
        }
      }
      allowPreregistration
      internalTitle
      externalTitle
      evaluationGoal
      learningObjectives
      audience
      agreement
      partnerName
      registrationOpenDate
      registrationCloseDate
      startDate
      endDate
      isOngoing
      meetingLink
      caseFormLink
      surveyLink
      scheduleDescription
      registrationQuestions {
        id
        title
        type
        name
        required
        isMultiSelect
        yesNo {
          noText
          yesText
        }
        options {
          label
          value
        }
        hasOtherOption
        scale {
          label
        }
        statements {
          name
          statement
        }
      }
      sessions {
        id
        title
        number
        startDate
        endDate
        sessionResources {
          id
          resource {
            name
            displayName
            url
            archived
            deleted
          }
        }
      }
      preRegistrantCount
      newRegistrantCount
      registeredCount
      waitlistedCount
      deniedCount
      developmentUser {
        id
        firstName
        lastName
        email
      }
      implementationUser {
        id
        firstName
        lastName
        email
      }
      evaluationUser {
        id
        firstName
        lastName
        email
      }
      developmentSupportUser {
        id
        firstName
        lastName
        email
      }
      evaluationSupportUser {
        id
        firstName
        lastName
        email
      }
      implementationSupportUser {
        id
        firstName
        lastName
        email
      }
    }
  }
`;
